import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { ReceiptByDateComponent } from './receipt-by-date/receipt-by-date.component';
import { HomeComponent } from './home/home.component';

// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    ReceiptByDateComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      { path: 'receipts/date/:date', component: ReceiptByDateComponent },
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
