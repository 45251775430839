import {Component, OnInit} from '@angular/core';
import { ApiService } from './api.service';
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
    constructor(private apiService: ApiService) { }

    title = 'A Summer Garden';
    getAnimationData(outlet: RouterOutlet) {
      return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}
