import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-receipt-by-date',
  templateUrl: './receipt-by-date.component.html',
  styleUrls: ['./receipt-by-date.component.less']
})
export class ReceiptByDateComponent implements OnInit {
  commandes: any;
  date: any;
  constructor(private route: ActivatedRoute, private apiService: ApiService) { }
  title = 'A Summer Garden - Commandes';

  ngOnInit() {
    this.date = this.route.snapshot.paramMap.get("date");
    this.apiService.get('/receipts/date/' + this.date).subscribe((data) => {
      // tslint:disable-next-line:only-arrow-functions
      this.commandes = data;
      console.log(data);
    });
  }

}
