import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
  constructor(private apiService: ApiService) { }

  title = 'A Summer Garden';
  statsCaWeek = {};
  statKeysWeek = [];
  nbOrdersWeek = 0;
  thInitWeek = false;

  statsCaMonth = {};
  statKeysMonth = [];
  nbOrdersMonth = 0;
  thInitMonth = false;

  statsCaDay = {};
  statKeysDay = [];
  nbOrdersDay = 0;
  thInitDay = false;

  ngOnInit() {
    this.apiService.get('/receipts/ca_per_week/json/4').subscribe((data) => {
      // tslint:disable-next-line:only-arrow-functions
      let week;
      for (week of Object.keys(data)) {
        this.statsCaWeek[week] = data[week];
        let key;
        for (key of Object.keys(data[week])) {
          console.log(key);
          if (key === 'Nombre de commande') {
            this.nbOrdersWeek += parseInt(data[week][key], 0);
          }
          if (!this.thInitWeek) {
            this.statKeysWeek.push(key);
            console.log(this.statKeysWeek);
          }
        }
        this.thInitWeek = true;
      }
      this.statsCaWeek = data;
    });

    this.apiService.get('/receipts/ca_per_month/json/12').subscribe((data) => {
      // tslint:disable-next-line:only-arrow-functions
      let month;
      for (month of Object.keys(data)) {
        this.statsCaMonth[month] = data[month];
        let key;
        for (key of Object.keys(data[month])) {
          console.log(key);
          if (key === 'Nombre de commande') {
            this.nbOrdersMonth += parseInt(data[month][key], 0);
          }
          if (!this.thInitMonth) {
            this.statKeysMonth.push(key);
            console.log(this.statKeysMonth);
          }
        }
        this.thInitMonth = true;
      }
      this.statsCaMonth = data;
    });

    this.apiService.get('/receipts/ca_per/day/json/30').subscribe((data) => {
      // tslint:disable-next-line:only-arrow-functions
      let day;
      for (day of Object.keys(data)) {
        this.statsCaDay[day] = data[day];
        let key;
        for (key of Object.keys(data[day])) {
          console.log(key);
          if (key === 'Nombre de commande') {
            this.nbOrdersDay += parseInt(data[day][key], 0);
          }
          if (!this.thInitDay) {
            this.statKeysDay.push(key);
            console.log(this.statKeysDay);
          }
        }
        this.thInitDay = true;
      }
      this.statsCaDay = data;
    });
  }

}
