import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
    endpoint: string;

    constructor(private httpClient: HttpClient) {
        this.endpoint = 'https://api.asummergarden.fr';
    }

    public get(url: string) {
      return this.httpClient.get(this.endpoint + url, {
      });
    }
}
